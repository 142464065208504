import { Link } from 'gatsby'
import React from 'react'
import { Row, Col } from 'react-flexbox-grid'

import Logo from '../../images/logo-dark.svg'
import SocialMenu from '../SocialMenu'

export default function FooterMenu({ showSocialLabel = false }) {
  const today = new Date()

  return (
    <Row className='footer-menu'>
      <Col md={3} className='hidden-sm'>
        <Link to='/'>
          <img src={Logo} alt='biz-logo' />
        </Link>
      </Col>
      <Col xs={12} md={5} className='social-wrapper'>
        {showSocialLabel && (
          <>
            <div className='heading-3'>Social</div>
            <div className='divider' />
            <br />
          </>
        )}
        <SocialMenu />
      </Col>
      <Col md={4} className='link-wrapper'>
        <Link to='/terms' className='link'>
          Terms of Use
        </Link>
      </Col>
      <Col xs={12} className='copyright'>
        <p className='para-text'>{`Copyright © ${today.getFullYear()}  Synonym Software Ltd. All rights reserved.`}</p>
      </Col>
    </Row>
  )
}
