import React, { useContext } from 'react'

import Episodes from '../components/Episodes'
import FooterMenu from '../components/FooterMenu'
import Header from '../components/Header'
import Layout from '../components/Layout'
import PageContent from '../components/PageContent'
import SEO from '../components/SEO'
import AppContext from '../context/AppContext'

function EpisodesPage() {
  const { podcasts } = useContext(AppContext)

  return (
    <Layout>
      <SEO title='All Episodes' />
      <PageContent>
        <Header />
        <Episodes podcasts={podcasts} showSearch showLoader />
        <br />
        <FooterMenu />
      </PageContent>
    </Layout>
  )
}

export default EpisodesPage
